$(function(){

    // Start
    if (document.querySelector('#start') !== null) {
        Inputmask({
            mask: "99/99/9999",
            placeholder: "dd/mm/yyyy",
            clearIncomplete: true,
            showMaskOnHover: false,
            showMaskOnFocus: true,
            clearMaskOnLostFocus: true
        }).mask(document.querySelector('input#birthdate'));
    }

    // Forms
    if (document.querySelector('form') !== null) {
        document.querySelector('form').addEventListener('submit', function(e) {
            e.preventDefault();
            grecaptcha.ready(function() {
                grecaptcha.execute('6LeGMWcaAAAAAAO_lfpZL9dxvsw6KGiIZyh8robA', {action: 'submit'}).then(function(token) {
                    $('<input>').attr({
                        type: 'hidden',
                        name: '_recaptcha',
                        value: token,
                    }).appendTo(e.target);
                    e.target.submit();
                });
            });
        });
    }
});
